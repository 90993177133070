import { useEffect, useState } from 'react'

const useBot = (defaultValue = true) => {
  const [isBot, setIsBot] = useState(defaultValue)

  useEffect(() => {
    const botPatternsReg =
      /[a-z0-9\-_]*(bot|crawl|archiver|transcoder|spider|uptime|validator|fetcher|cron|checker|reader|extractor|monitoring|analyzer|scraper|chrome-lighthouse|googlebot|crawler|robot|crawling|lighthouse)/i

    setIsBot(botPatternsReg.test(window.navigator.userAgent))
  }, [])

  return { isBot }
}

export { useBot }
